//
// datepicker.scss
//

.btn-white {
  background-color: $white;
  border-color: $white;
}

.btn-red-shift {
  background: $red-100;
  color: $white;
}

.btn-blue-shift {
  background: $blue-100;
  color: $white;
}

.btn-green-shift {
  background: $green-100;
  color: $white;
}

.calendar-display-date {
  color: white;
  border: none;
  cursor: pointer;
  padding: 0 0;
  font-size: 2em;
  width: 440px;
  height: 40px;
  @media  (max-width: 767px) {
    width: 220px;
  }
  // @media  (max-width: 490px) {
  //   width: 100%;
  //   font-size: 11px;
  // }
}

.test-range {
  color:red;
}

.wrapper-calendar{
  width: 100%;
  display: flex;
  justify-content: center;
}

.responsive-calendar {
  font-size: 10px;
}

.calendar-disabled-container {
  position: relative;
  display: inline-block;
  z-index: 100;
}

.calendar-disabled {
  font-family: inherit;
  text-align: center;
  cursor: default;
}

@media (max-width: 768px) {
  .responsive-calendar {
    font-size: 8px !important;
  }
}
