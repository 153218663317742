@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
    @include ag-theme-alpine((
    ));

    font-family: inherit;
    font-size: inherit;

    .ag-row {
        font-size: inherit;
    }
}