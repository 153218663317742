.department-input {
    border: 1.5px grey solid;
    border-radius: 0.6em;
    height: 2.5em;
}

.department-input:focus {
    outline: 0px;
}

.spacer {
    display: block;
    width: 20px;
}

.toggle-section {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    width: 50%;
}

.virtual-scrolling {
    height: 8rem;
    width: 100%;
    overflow: auto;
}

.module-name {
    padding-left: 10px;
    margin-top: -3px;
}

.department-logo-edit {
    img {
        width: 100px;
        min-width: 100px;
        padding: 10px;
        height: 70px;
        min-height: 90px;
    }
}
