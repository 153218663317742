.delete-strike-team-btn {
  cursor: pointer !important;
  color: darkgray;
}

.delete-strike-team-btn-disabled {
  cursor: default !important;
  color: darkgray;
}
.incident-input {
  border: 1px solid #eef2f7!important;
}

.add-answer-btn {
  font-size: 25px;
  cursor: pointer;
  color: #008fd2;
}
