textarea {
  border: 1px solid #eef2f7!important;
}

.demo-editor {
  margin: 0 !important;
}

.public-DraftEditor-content > div > div:first-child {
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    margin: 1em 0 0 0;
  }
}

.public-DraftEditor-content > div > div:not(:first-child):not(:last-child) {
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    margin: 0;
  }
}

.public-DraftEditor-content > div > div:last-child {
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    margin: 0 0 1em 0;
  }
}

.public-DraftEditor-content > div > div:only-child {
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    margin: 1em 0;
  }
}
