.navbar-brand-box {
    padding: 0 !important;

    .logo, .logo-sm > img, .logo-lg > img {
        height: 42px;
    }

    .logo-sm{
        padding: 4px 4px;
    }
}

.department-logo {
    img {
        width: 70px;
        min-width: 70px;
        padding: 10px;
    }
}