.list-container {
    list-style: none;
    padding: 10px;
}

.list-item::before {
  font-weight: 900;
  content: url("../../assets/images/Bullet.png");
}

.list-item-child::before {
  content: url("../../assets/images/Bullet.png");
}

.list-item {
  color: black;
  padding: 11px;
  font-weight: 400;
  font-size: 15px;
  text-indent: -1.9em;
  margin-left: 0;
}

.list-content-text {
  color: black;
  padding-left: 17px;
}

.list-content-child-text {
  color: black;
  padding-left: 12px;
  line-height: 2.5;
}

.box-card {
  max-width: 100%;
  display: block;
}
.userconsole-parent{
    width: 100%;
}

.masonry-layout {
  @media (min-width: 768px) {
    --columns: 1;
    --gap: 1.2rem;
    $columns: 6;

    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-gap: var(--gap);

    .masonry-item {
      margin-bottom: var(--gap);
    }

    @for $i from 1 through $columns {
      &.columns-#{$i} {
        --columns: #{$i};
      }
    }
  }
}

.subChevAlign {
    margin-top:12px;
}
