// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

.blue-bar {
  background: $blue;
}

.red-bar {
  background: $red-100;
}

.green-bar {
  background: $green-100;
}

.blue-button {
  color: $blue;
}

.red-button {
  color: $red-100;
}

.green-button {
  color: $green-100;
}

.blue-button:hover {
  color: $blue;
}

.red-button:hover {
  color: $red-100;
}

.green-button:hover {
  color: $green-100;
}
