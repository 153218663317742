.fgCheckbox {
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  > input {
    flex: 0 1 15px;
    margin-right: 5px;
    margin-top: 3px;
  }

  > label {
    flex: 1;
    margin: 0;
    white-space: nowrap;
  }
}

.fgCheckboxWith2ndLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > span {
    flex: 1;
    padding-right: 5px;
  }
  > div {
    flex: 0;
  }
}
