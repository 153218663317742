
.section-checkbox{
    margin-bottom: 30px;
}


/*SliderCheckbox*/
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 21px;
    margin: 0px 22px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: -2.5px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    margin-bottom: 2.5px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2a9fd8;
}

input:focus + .slider {
    box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 36px;
}

.slider.round:before {
    border-radius: 50%;
}


.section p{
    font-weight: bold;
}

.roles-container{
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 6px;
    margin-top: -5px;
}

.user-roles-container{
    overflow-y: hidden;
    border: 1px solid #ccc;
    padding: 6px;
    margin-top: -5px;
}

.roles input{
    margin-right: 10px;
}

.roles label{
    display: inline-block;
    align-items: center;
}

.roles-checkbox{
    position: relative;
    top: 2px;
    font-weight: 100;
}

.pass-reset-button {
    border: none;
    padding: 10px 24px;
    border-radius: 18px;
    background-color: #2a9fd8;
    color: white;
}

.pass-reset-button:active {
    background-color: #227eac;
}

.pass-reset-button-login {
    border: none;
    padding: 10px 24px;
    border-radius: 18px;
    background-color: #2a9fd8;
    color: white;
}




.status-container input{
    color: black;
}

.disableColor {
    background-color: #97c7de;
}